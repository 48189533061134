import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient, private storageService:StorageService) {}
  private getAuthHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.storageService.getCookie("userSession")}`,
    });
  }
  getAllCategories() {
    return this.http.get('https://stockapi.myftp.org/api/v1/categories',{
    headers: this.getAuthHeaders(),
  });
  }
  getCategoryByID(cat: string) {
    let url: string =
      'https://stockapi.myftp.org/api/v1/categories/category/' + cat;
    return this.http.get(url,{
    headers: this.getAuthHeaders(),
  });
  }

  searchCoins(seachvalue: String) {
    return this.http.get(
      'https://stockapi.myftp.org/api/v1/products/search/' + seachvalue
    ,{
    headers: this.getAuthHeaders(),
  });
  }
  getCategoriesById(cat: string) {
    let url: string =
      'https://stockapi.myftp.org/api/v1/products/category/' + cat;
    return this.http.get(url,{
    headers: this.getAuthHeaders(),
  });
  }

  getCoinById(coin: string) {
    let url: string = 'https://stockapi.myftp.org/api/v3/products/' + coin;
    return this.http.get(url,{
    headers: this.getAuthHeaders(),
  });
  }
  getRelatedCoins(coin: string) {
    let url: string = 'https://stockapi.myftp.org/api/v3/products/related/' + coin;
    return this.http.get(url,{
    headers: this.getAuthHeaders(),
  });
  }

  
  getShippingOptions() {
    let url: string = 'https://stockapi.myftp.org/api/v1/products/shipping ';
    return this.http.get(url,{
    headers: this.getAuthHeaders(),
  });
  }

  getBestseller() {
    return this.http.get('https://stockapi.myftp.org/api/v1/bestseller',{
    headers: this.getAuthHeaders(),
  });
  }

  getAllCoins() {
    return this.http.get('https://stockapi.myftp.org/api/v3/products/shopcoins',{
    headers: this.getAuthHeaders(),
  });
  }
  getTimerProduct() {
    return this.http.get('https://stockapi.myftp.org/api/v3/products/timerproduct',{
    headers: this.getAuthHeaders(),
  });
  }
  getAllCountries() {
    return this.http.get(
      'https://stockapi.myftp.org/api/v1/categories/countries'
    ,{
    headers: this.getAuthHeaders(),
  });
  }
  getAllConds() {
    return this.http.get(
      'https://stockapi.myftp.org/api/v1/categories/conditions'
    ,{
    headers: this.getAuthHeaders(),
  });
  }
  getConvoluts() {
    return this.http.get(
      'https://stockapi.myftp.org/api/v1/products/convoluts'
    ,{
    headers: this.getAuthHeaders(),
  });
  }

  saveCoin(obj: any) {
    return this.http.post<any>('https://stockapi.myftp.org/api/v1/products' , obj,{
    headers: this.getAuthHeaders(),
  });
  }
  updateCoin(obj: any, id: number) {
    return this.http.patch<any>('https://stockapi.myftp.org/api/v1/products/'+id , obj,{
    headers: this.getAuthHeaders(),
  });
  }
  changeShop( id: number) {
    return this.http.get<any>('https://stockapi.myftp.org/api/v1/products/shop/'+id ,{
    headers: this.getAuthHeaders(),
  });
  }
}
